import React from 'react';
import PropTypes from 'prop-types';

import { Panel, PanelHeader, Header, Button, Group, Cell, Div, Avatar } from '@vkontakte/vkui';

const Home = ({ id, go, user }) => (
	<Panel style={{}} id={id}>
		<PanelHeader>Тренажер ЕГЭ по английскому языку</PanelHeader>
		{user &&
		<Group header={<Header mode="secondary">Пользователь</Header>}>
			<Cell
				before={user.photo_200 ? <Avatar src={user.photo_200}/> : null}
				description={user.city && user.city.title ? user.city.title : ''}
			>
				{`${user.first_name} ${user.last_name}`}
			</Cell>
		</Group>}

		<Group>
			<Div>
				<div style={{ height: '60px', width: '100%'}}></div>
			</Div>
			<Div>
				<Button stretched size="l" mode="secondary" onClick={go} data-to="variants">
					Варианты ЕГЭ
				</Button>
			</Div>
			{/* <Div>
				<Button stretched size="l" mode="secondary"  data-to="audio">
					Аудирование
				</Button>
			</Div>*/}
			<Div>
				<Button stretched size="l" mode="secondary" onClick={go} data-to="grammar">
					Грамматический тренажер
				</Button>
			</Div> 
			<Div>
				<Button stretched size="l" mode="secondary" onClick={go} data-to="stats">
					Персональная статистика
				</Button>
			</Div>
			{/* <Div>
				<Button stretched size="l" mode="secondary" onClick={go} data-to="table">
					Таблица достижений
				</Button>
			</Div> */}
			<Div>
				<div style={{ height: '220px', width: '100%'}}></div>
			</Div>
		</Group>
	</Panel>
);

Home.propTypes = {
	id: PropTypes.string.isRequired,
	go: PropTypes.func.isRequired,
	user: PropTypes.shape({
		photo_200: PropTypes.string,
		first_name: PropTypes.string,
		last_name: PropTypes.string,
		city: PropTypes.shape({
			title: PropTypes.string,
		}),
	}),
};

export default Home;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { saveVariantAnswers } from '../../actions';
import { checkAnswer, fillAnswers } from '../ege/utils';

import Task from './tasks/Task';

import Timer from './comps/Timer';
import ButtonGroup from './comps/ButtonGroup';
import Solution from './comps/Solution';
import Instruction from './comps/Instruction';
import EGEHeader from './comps/EGEHeader';

import { Panel, Group } from '@vkontakte/vkui';





const Ege = ({ id, go, variantId }) => {
    const [config, setConfig] = useState(null);
    const [taskNumber, setTaskNumber] = useState(-1);
    const [answers, setAnswer] = useState([]);

    const dispatch = useDispatch();

    console.log('EGE answers', answers);

    useEffect(() => {
        axios.get(`/vars/${variantId}/${variantId}.json`).then(resp => {
            setConfig(resp.data);
        });
	}, []);

    const start = () => {
        setTaskNumber(0);
    }

    const saveAnswer = (answer) => {
        console.log('saveAnswer', answer);
        answers[taskNumber] = answer;
        setAnswer([ ...answers ]);
    }

    const toPrev = () => {
        let newTaskNumber = taskNumber - 1;
        setTaskNumber(newTaskNumber);
    }

    const toNext = () => {
        let newTaskNumber = taskNumber + 1;
        setTaskNumber(newTaskNumber);
    }

    const saveAnswers = (answ) => {
        console.log('saveAnswers');
        dispatch(saveVariantAnswers(variantId, answ || answers));
    }

    const toResult = (e) => {
        saveAnswers();
        go(e);
    }

    const startTimer = () => {
        if(taskNumber > -1) {
            const time = new Date();
            time.setSeconds(time.getSeconds() + 12420); // 3:27 minutes timer
            return (<Timer style={{ float: 'right' }} expiryTimestamp={time} expire={timerExpire} />);
        }
    }

    const timerExpire = () => {
        console.log('timerExpire', answers);
        const answ = fillAnswers(answers, config.tasks.length);
        setAnswer(answ);
        saveAnswers(answ);
        go(null, 'result');
    }

    const solve = () => {
        if (!answers[taskNumber]) {
            answers[taskNumber] = { input: {} };
        }
        answers[taskNumber].isSolutionShown = true;
        setAnswer([ ...answers ]);
    }

    const getHeaderState = () => {
        if (!answers[taskNumber] || !answers[taskNumber].isSolutionShown) {
            return 0;
        }
        const isRight = checkAnswer(config.tasks[taskNumber], answers[taskNumber]);

        return isRight ? 2 : 1;
    }

    return (
	<Panel id={id}>
        <EGEHeader isRight={getHeaderState()} startTimer={startTimer} variant={variantId} go={go} taskNumber={taskNumber} taskId={config && config.tasks[taskNumber]?.id} />
        <Instruction showInstruction={taskNumber === -1} instruction={config && config.instruction} start={start} />
        <Task disableInput={answers[taskNumber] && answers[taskNumber].isSolutionShown} taskNumber={taskNumber} config={config} answers={answers} saveAnswer={saveAnswer} />
        <Solution solve={solve} explain={config && config.tasks[taskNumber]?.explain} taskNumber={taskNumber} showSolution={answers[taskNumber] && answers[taskNumber].isSolutionShown}/>
        <ButtonGroup tasksLength={config && config.tasks.length} taskNumber={taskNumber} toPrev={toPrev} toNext={toNext} toResult={toResult} />
        { taskNumber > 0 && (<Group><span style={{ display: 'inline-block', padding: '7px 15px'}}><b>Источник:  </b>
            {config && config.tasks[taskNumber] && config.tasks[taskNumber].source}</span></Group>)
        }
	</Panel>);
}

Ege.propTypes = {
	id: PropTypes.string.isRequired,
	go: PropTypes.func.isRequired
};

export default Ege;

import isEqual from 'lodash/isEqual';
import words from 'lodash/words';


export function checkAnswer(task, answer) {
    if(!answer) {
        return false;
    }

    switch(task.input.type) {

        case "table":
            stringValuesToNumbers(answer.input);
            return isEqual(answer.input, task.answer);

        case "test":
            return task.answer.includes(Number(answer.input[0]));

        case "input":
            if(typeof answer.input === "string") {
                const clearAnswer = words(answer.input.toLowerCase()).join('');
                return task.answer.includes(clearAnswer);
            } else {
                return false;
            }
                
        case "textarea":
            if(typeof answer.input === "string")
                return answer.input.trim().length > 5; // 
            else
                return false;

        case "voice":
            return true;

    }
}

export function checkAnswers(tasks, answers) {
    let results = [];
    for (let i = 0; i < tasks.length; i++) {
        const answ = checkAnswer(tasks[i], answers[i]);
        results.push(answ);
    }
    return results;
}

export function countScores(tasks, answBool) {
    let result = 0;
    for (let i = 0; i < tasks.length; i++) {
        if(answBool[i]) {
            result += tasks[i].score;
        }
    }
    return result;
}

export function countPositive(arr) {
    return arr.filter(Boolean).length;
}

export function countAnswersByCode(tasks, answers) {
    let solved = {};
    let all = {};
    for (let i = 0; i < tasks.length; i++) {
        let answ = checkAnswer(tasks[i], answers[i]);
        if (!solved[tasks[i].codeFIPI]) {
            solved[tasks[i].codeFIPI] = 0;
        }
        if (!solved[tasks[i].codeFIPI] && answ) {
            solved[tasks[i].codeFIPI] = 1;
        } else if (solved[tasks[i].codeFIPI] && answ) {
            solved[tasks[i].codeFIPI]++;
        }
        if(!all[tasks[i].codeFIPI]) {
            all[tasks[i].codeFIPI] = 1;
        } else {
            all[tasks[i].codeFIPI]++;
        }
    }
    return { solved , all };
}

export function makeObjectEmpty(obj) {
    let empty = Object.assign({}, obj);
    Object.keys(empty).forEach(key => {
        empty[key] = '';
    });
    return empty;  
}

export function stringValuesToNumbers(obj) {
    Object.keys(obj).forEach((key) => obj[key] = Number(obj[key]));
}

export function shuffle(arr) {
    let array = JSON.parse(JSON.stringify(arr));
    let currentIndex = array.length,  randomIndex;
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
    return array;
}

export function fillAnswers(answers, length) {
    if(answers.length < length) {
        for(let i = answers.length; i < length; i++) {
            answers[i] = undefined;
        }
    }
    return answers;
}
  


//tests
//console.log('checkAnswer true', checkAnswer({ "input": { "type": "table" }, answer: { "A": 6, "B": 2, "C": 1, "D": 7, "E": 5, "F": 3 }}, { input: { "A": 6, "B": 2, "C": 1, "D": 7, "E": 5, "F": 3 }})) // true
//console.log('checkAnswer false', checkAnswer({ "input": { "type": "table" }, answer: { "A": 6, "B": 2, "C": 1, "D": 7, "E": 5, "F": 7 }}, { input: { "A": 6, "B": 2, "C": 1, "D": 7, "E": 5, "F": 3 }})) // true
//console.log('fillAnswers', fillAnswers([], 42));
//console.log('fillAnswers', fillAnswers([{}, {}, {}, {}], 42));
//console.log('fillAnswers', fillAnswers([{}, {}, {}, {}], 4))
import React from 'react';
import { PanelHeader, PanelHeaderBack, } from '@vkontakte/vkui';





export default function GrammarHeader({ go, taskNumber, taskId, isRight }) {

  const setColor = () => {
    switch(isRight) {
        case 0: // нетронутый
            return 'black';
        break;

        case 1: // ошибка
            return 'red';
        break;

        case 2: // все правильно
            return 'green';
        break;
    }
  }
 
  return (
    <PanelHeader
        left={<PanelHeaderBack onClick={go} data-to="home"/>}
    >
        {`Грамматика` }
        { taskNumber > -1 ? (<div style={{ display: 'inline-block', paddingBottom: '-10px', margin: '0 10px -10px 10px', borderLeft: '2px solid #447bba', height: '35px'}}></div>) : null }
        <span style={{ color: setColor() }}>{ taskNumber > -1 ? `Тип ${taskNumber + 1} № ${taskId}` : ''}</span>
    </PanelHeader>
    );
}
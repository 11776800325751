import axios from 'axios';
import bridge from '@vkontakte/vk-bridge';
import { 
    FETCH_USER,
    FETCH_ERROR,
    SET_LOADER,
    SET_VARIANT_ANSWERS,
    SET_TOKEN,
    GET_ALL_VARIANTS_ANSWERS
} from "./actionTypes";
import store from '../store';

export const setLoader = (showLoader) => async dispatch => {
    dispatch({
        type: SET_LOADER,
        payload: showLoader
    })
}

export const fetchUser = () => async dispatch => {
    setLoader(true);
    try {
        let userVK = await bridge.send('VKWebAppGetUserInfo');
        dispatch({
            type: FETCH_USER,
            payload: userVK
        });
        setLoader(false);
    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: error
        });
    }
}


export const saveVariantAnswers = (variantId, answers) => async dispatch => {
    setLoader(true);
    try {
        let saved = await bridge.send('VKWebAppStorageSet', { key: variantId, value: JSON.stringify(answers) });
        dispatch({
            type: SET_VARIANT_ANSWERS,
            payload: {
                [variantId]: answers
            }
        });
        setLoader(false);
    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: error
        });
    }
      
}

export const getVariantAnswers = (variantId) => async dispatch => {
    setLoader(true);
    try {
        //const access_token = store.getState().auth.access_token;
        let answers = await bridge.send('VKWebAppStorageGet', { keys: [variantId] });
        let variant;
        if (answers.keys[0].value && isJsonString(answers.keys[0].value)) {
            variant = JSON.parse(answers.keys[0].value);
        }
        dispatch({
            type: SET_VARIANT_ANSWERS,
            payload: {
                [variantId]: variant
            }
        });
        setLoader(false);
    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: error
        });
    }
}

export const getAllVariantsAnswers = (variantIds) => async dispatch => {
    setLoader(true);
    try {
        //const access_token = store.getState().auth.access_token;
        let answers = await bridge.send('VKWebAppStorageGet', { keys: variantIds });
        let variants = {};

        console.log('getAllVariantsAnswers', answers.keys);

        for (let i = 0; i < answers.keys.length; i++) {
            if(answers.keys[i].value && isJsonString(answers.keys[i].value)) {
                variants[answers.keys[i].key] = JSON.parse(answers.keys[i].value);
            } else {
                variants[answers.keys[i].key] = null;
            }
        }
        dispatch({
            type: SET_VARIANT_ANSWERS,
            payload: variants
        });
        setLoader(false);
    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: error
        });
    }
}


export const getAuthToken = () => async dispatch => {
    try {
        let token = await bridge.send('VKWebAppGetAuthToken', { app_id: 51468922, scope: 'friends,status' });
        console.log('getAuthToken', token );
        dispatch({
            type: SET_TOKEN,
            payload: token
        });
    } catch (error) {
        dispatch({
            type: FETCH_ERROR,
            payload: error
        });
    }
}


function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { saveVariantAnswers } from '../../actions';
import { checkAnswer, shuffle } from '../ege/utils';

import Task from './tasks/Task';

import ButtonGroup from './comps/ButtonGroup';
import Solution from './comps/Solution';
import Instruction from './comps/Instruction';
import GrammarHeader from './comps/GrammarHeader';

import { Panel, Group } from '@vkontakte/vkui';



const Grammar = ({ id, go }) => {
    const [config, setConfig] = useState(null);
    const [taskNumber, setTaskNumber] = useState(-1);
    const [answers, setAnswer] = useState([]);

    const dispatch = useDispatch();

    console.log('grammar answers', answers, config);

    useEffect(async () => {
        axios.get(`/vars/grammar/grammar.json`).then(resp => {
            const config = resp.data;
            config.tasks = shuffle(config.tasks);
            setConfig(config);
        });
	}, []);


    const toResult = (e) => {
        console.log('toResult', e);
        setAnswer([]);
        setTaskNumber(0)
        //saveAnswers();
        //go(e);
    }

    const start = () => {
        setTaskNumber(0);
    }

    const saveAnswer = (answer) => {
        console.log('saveAnswer', answer);
        answers[taskNumber] = answer;
        setAnswer([ ...answers ]);
    }

    const toPrev = () => {
        let newTaskNumber = taskNumber - 1;
        setTaskNumber(newTaskNumber);
    }

    const toNext = () => {
        let newTaskNumber = taskNumber + 1;
        setTaskNumber(newTaskNumber);
    }

    const solve = () => {
        if (!answers[taskNumber]) {
            answers[taskNumber] = { input: {} };
        }
        answers[taskNumber].isSolutionShown = true;
        setAnswer([ ...answers ]);
        dispatch(saveVariantAnswers('grammar', [ ...answers ]));
    }

    const getHeaderState = () => {
        if (!answers[taskNumber] || !answers[taskNumber].isSolutionShown) {
            return 0;
        }
        const isRight = checkAnswer(config.tasks[taskNumber], answers[taskNumber]);

        return isRight ? 2 : 1;
    }

    if (!config) return null;

    return (
	<Panel id={id}>
        <GrammarHeader isRight={getHeaderState()} go={go} taskNumber={taskNumber} taskId={config && config.tasks[taskNumber]?.id} />
        <Instruction showInstruction={taskNumber === -1} instruction={config && config.instruction} start={start} />
        <Task disableInput={answers[taskNumber] && answers[taskNumber].isSolutionShown} taskNumber={taskNumber} config={config} answers={answers} saveAnswer={saveAnswer} />
        <Solution solve={solve} explain={config && config.tasks[taskNumber]?.explain} taskNumber={taskNumber} showSolution={answers[taskNumber] && answers[taskNumber].isSolutionShown}/>
        <ButtonGroup tasksLength={config && config.tasks.length} taskNumber={taskNumber} toPrev={toPrev} toNext={toNext} toResult={toResult} />
        { taskNumber > 0 && (<Group><span style={{ display: 'inline-block', padding: '7px 15px'}}><b>Источник:  </b>
            {config && config.tasks[taskNumber] && config.tasks[taskNumber].source}</span></Group>)
        }
	</Panel>);
}

Grammar.propTypes = {
	id: PropTypes.string.isRequired,
	go: PropTypes.func.isRequired
};

export default Grammar;
